@import "../../../../common-v2.scss";
.cmenu-product-filter-header {
  width: 100%;
  .product-filter-header-title {
    font-family: "Bebas";
    font-style: normal;
    font-weight: 400;
    font-size: get-font-vw(20);
    line-height: get-font-vw(24);
  }
}
