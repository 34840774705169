@import "../../../../common-v2.scss";
.cmenu-chidren-filter-attribute-wrapper {
  display: flex;
  //width: 100%;
  flex-direction: column;
  margin-left: get-vw(38px);
  .expandCollapseIcon {
    margin-left: -2px;
    &.-selected {
      transform: rotate(90deg);
    }
  }
  .parent-item-name {
    margin-top: get-vw(7px);
    margin-bottom: get-vw(7px);
    font-family: "Barlow";
    font-style: normal;
    font-weight: 500;
    font-size: get-font-vw(14);
    line-height: get-font-vw(17);
  }
  h1.checkbox-form-control-label {
    margin-top: get-vw(7px);
    margin-bottom: get-vw(7px);
    font-family: "Barlow";
    font-style: normal;
    font-weight: 500;
    font-size: get-font-vw(14);
    line-height: get-font-vw(17);
  }
  h1.checkbox-form-control-label-selected {
    margin-top: get-vw(7px);
    margin-bottom: get-vw(7px);
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: get-font-vw(14);
    line-height: get-font-vw(17);
  }
}
