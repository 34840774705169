@import "../../../../common-v2.scss";
.lineage-main-container {
  position: relative;
  // min-height: 100px;
  width: 100%;
  margin-top: get-vw(47px);
  .lineage-inner-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-left: getNextPreviousPadding();
    margin-right: getNextPreviousPadding();
    &.-open {
      margin-left: get-vw(0px);
      margin-right: get-vw(0px);
    }
    .lineage-item-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-right: get-vw(34.62px);
      .lineage-box {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: get-vw(37.6294px);
        width: get-vw(156px);
        height: get-vw(43px);
        h1.lineage-title {
          font-family: "Bebas";
          font-weight: 400;
          font-style: normal;
          font-size: get-font-vw(19);
          line-height: get-font-vw(23);
          letter-spacing: 0.1em;
          margin: auto;
          padding: 0px;
          min-height: 0px;
          min-width: 0px;
        }
      }
    }
  }
}
