@import "../../../../common-v2.scss";
.cmenu-product-card-overlay {
  display: none;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: get-vw(0px) get-vw(0px);
  padding-bottom: get-vw(12px);
  transform: scaleY(0);
  &.-expanded {
    transition: transform 0.3s ease;
    // transition-delay: 25ms;
    height: auto;
    transform: scaleY(1);
    transform-origin: bottom;
  }
  .quantity-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h1.quantity-title {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 600;
      font-size: get-font-vw(21.3125);
      line-height: get-font-vw(25);
      letter-spacing: 0.2em;
      padding: get-vw(0px) get-vw(13px);
    }
  }
}
