$desktopDesignWidth: 1512; // as per the style guide in figma
$mobileDesignWidth: 428; // as per the mobile web style guide in figma

:root {
    --baseWidthRatioForFont: #{$desktopDesignWidth};
}
  
@media (max-width: 600px) {
    :root {
        --baseScreenWidthRatioForFont: calc(#{$mobileDesignWidth}* 0.01);
    }
}

@media (min-width: 601px) {
    :root {
        --baseScreenWidthRatioForFont: calc(#{$desktopDesignWidth}* 0.01);
    }
}