@import "../../../../common-v2.scss";
.cmenu-range-slider-wrapper {
  display: flex;
  width: inherit;
  flex-direction: column;
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-top: get-vw(21px);
  .range-slider-header {
    width: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .min-text-info {
      padding-bottom: get-vw(8px);
      font-family: "Barlow";
      font-style: normal;
      font-weight: 500;
      font-size: get-font-vw(14);
      line-height: get-font-vw(17);
    }
    .max-text-info {
      padding-bottom: get-vw(8px);
      font-family: "Barlow";
      font-style: normal;
      font-weight: 500;
      font-size: get-font-vw(14);
      line-height: get-font-vw(17);
    }
  }
  .range-slider-ui-item {
    width: 100%;
  }
}
