@import "../../../../common-v2.scss";
#weight-box-container {
    display: grid;
    padding: 0;
    margin: 0;
    gap: get-vw(23px);
    row-gap: get-vw(12px);
    width: 100%;
}
#weight-box-container-list {
    display: grid;
    padding: 0;
    margin: 0;
    gap: get-vw(23px);
    row-gap: get-vw(12px);
    width: 100%;
    justify-items: center;
    align-items: center;
}