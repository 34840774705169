@import "../../../../common-v2.scss";

.signup-inner-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: get-vw(40px); // for testing only
  .left-container {
    margin-left: get-vw(96.5px);
    margin-right: get-vw(96.5px);
    width: get-vw(26px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
  .right-container {
    margin-left: get-vw(109.5px);
    margin-right: get-vw(109.5px);
  }
  .center-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .signup-content-container {
      height: inherit;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: get-vw(92px);
      margin-right: get-vw(92px);
      h2.header-title {
        font-family: "Bebas";
        font-style: normal;
        font-weight: 400;
        font-size: get-font-vw(36);
        line-height: get-font-vw(43);
        letter-spacing: 0.1em;
        color: #000000;
        text-align: left;
        text-transform: uppercase;
      }
      .button-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 0;
        gap: get-vw(10px);
        width: 100%;
        justify-items: center;
        align-items: center;
      }
    }
  }
}
