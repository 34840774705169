@import "../../../../common.scss";
.applied-filter-btn {
  &.MuiButton-root {
    min-height: get-vw(40px);
    min-width: get-vw(120px);
    margin: 4px;
    padding: get-vw(10px);
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: get-vw(10px);
    transform: matrix(1, 0, 0.01, 1, 0, 0);
    text-transform: none;
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: get-font-vw(20);
    line-height: get-font-vw(25);
  }
}
