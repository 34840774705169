@import "../../../../common.scss";
.product-filter-attributes-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  .rating-label-element {
    display: flex;
    flex-direction: row;
  }
  .checkbox-form-control-label {
    margin-bottom: get-vw(6px);
    & .MuiTypography-root {
      font-family: "Overpass";
      font-style: normal;
      font-weight: 500;
      font-size: get-font-vw(18);
      line-height: get-font-vw(22);
    }
  }
  .checkbox-form-control-label-selected {
    margin-bottom: get-vw(6px);
    & .MuiTypography-root {
      font-family: "Overpass";
      font-style: bold;
      font-weight: 600;
      font-size: get-font-vw(18);
      line-height: get-font-vw(22);
    }
  }
  .show-more-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -11px;
    margin-top: get-vw(10px);
    padding-left: get-vw(42px); //checkboxwidth
  }
}
