@import "../../../../common-v2.scss";

.locationBox {
  box-shadow: 0px -6px 36px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background: #ffffff;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: get-vw(40px);
}

.test .css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  background-color: transparent;
}

.titleCss {
  font-family: "Bebas";
  font-style: normal;
  font-weight: 400;
  font-size: get-font-vw(28.47);
  line-height: get-font-vw(34);
  letter-spacing: 0.1em;
  color: #000000;
  margin-bottom: get-vw(30px);
  margin-top: get-vw(50px);
  margin-left: get-vw(155px);
}
