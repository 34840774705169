@import "../../../../common.scss";
.product-filter-header {
  width: 100%;
  .product-filter-header-title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 500;
    font-size: get-font-vw(18);
    line-height: get-font-vw(30);
  }
}
