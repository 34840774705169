@import "../../../../common.scss";
.chidren-filter-attribute-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  .expandCollapseIcon {
    margin-left: -2px;
    &.-selected {
      transform: rotate(90deg);
    }
  }
  .parent-item-name {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 500;
    font-size: get-font-vw(18);
    line-height: get-font-vw(22);
  }
  .checkbox-form-control-label {
    margin-bottom: get-vw(6px);
    & .MuiTypography-root {
      font-family: "Overpass";
      font-style: normal;
      font-weight: 500;
      font-size: get-font-vw(18);
      line-height: get-font-vw(22);
    }
  }
  .checkbox-form-control-label-selected {
    margin-bottom: get-vw(6px);
    & .MuiTypography-root {
      font-family: "Overpass";
      font-style: bold;
      font-weight: 600;
      font-size: get-font-vw(18);
      line-height: get-font-vw(22);
    }
  }
}
