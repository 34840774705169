@import "../../../../common.scss";
.product-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: get-vw(50px);
  padding-bottom: get-vw(50px);
  &.-hide {
    display: none;
  }
  //background-color: yellow;
  .section-header-container {
    padding-top: get-vw(0px);
    padding-left: get-vw(5px);
    padding-right: get-vw(5px);
    //background-color: aquamarine;
    .category-title-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .category-title {
        font-family: "Overpass";
        font-style: bold;
        font-weight: 700;
        font-size: get-font-vw(40);
        line-height: get-font-vw(36);
      }
      .category-product-count-label {
        padding-left: get-vw(20px);
        font-family: "Overpass";
        font-style: bold;
        font-weight: 700;
        font-size: get-font-vw(24);
        line-height: get-font-vw(30);
        color: #000000;
        opacity: 0.5;
      }
    }
  }
}
