@import "../../../../common.scss";
.product-carousel{
    display: flex;
    flex-direction: row;
    width: inherit;
    padding-top: get-vw(20px);
    padding-bottom: get-vw(0px);
    &.-hide{
        display: none;
    }
}