@import "../../../../common.scss";
.product-filters-container {
  display: flex;
  flex: 0 0 270px;
  flex-direction: column;
  padding: get-vw(0px) get-vw(15px);
  background-color: #fafafa;
  .product-main-filter-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: inherit;
    padding: get-vw(0px) get-vw(16px);
    margin-bottom: get-vw(25px);
    .main-filter-title {
      font-family: "Overpass";
      font-style: normal;
      font-weight: 600;
      font-size: get-font-vw(24);
      line-height: get-font-vw(30);
      padding-right: get-vw(2px);
    }
  }
}
