@import "../../../../common.scss";
.breadcrum-container{
    padding:get-vw(0px) get-vw(31px);
}
.product-results-inner-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: get-vw(40px); // for testing only
    background-color: white;
    .product-sections-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 get-vw(10px);
        padding-bottom: get-vw(30px);
    }
}