@import "../../../../common-v2.scss";
.cmenu-product-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: get-vw(20px); //77 //38.5px
  padding-bottom: get-vw(57px); // 38.5px
  &.-hide {
    display: none;
  }
  &.-separated {
    padding-top: get-vw(0px); //77
    padding-bottom: get-vw(0px);
  }
  .section-header-container {
    padding-top: get-vw(0px);
    padding-left: get-vw(0px);
    padding-right: get-vw(0px);
    .category-title-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .category-title {
        font-family: "Bebas";
        font-style: normal;
        font-weight: 400;
        font-size: get-font-vw(32);
        line-height: get-font-vw(38);
        letter-spacing: 0.1em;
        white-space: nowrap;
      }
      .category-product-count-label {
        padding-left: get-vw(20px);
        font-family: "Barlow";
        font-style: normal;
        font-weight: 500;
        font-size: get-font-vw(14);
        line-height: get-font-vw(17);
        padding-top: get-font-vw(7.5);
        letter-spacing: 0.16em;
      }
    }
  }
}
