@import "../../../../common-v2.scss";
.product-filters-inner-container {
  .reset-btn-main-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .reset-btn-base {
      display: flex;
      margin-bottom: get-vw(17px);
      .reset-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: get-vw(30px);
        h1.reset-title {
          font-family: "Barlow"; //Bebas
          font-weight: 500;
          font-style: normal;
          font-size: get-font-vw(16);
          line-height: get-font-vw(19);
          display: flex;
          align-items: center;
          letter-spacing: 0.1em;
          margin: auto;
          padding: 0px;
          min-height: 0px;
          min-width: 0px;
        }
      }
    }
  }
}
