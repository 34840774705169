@import "../../../../common-v2.scss";
.cmenu-product-filters-container{
    display: flex;
    flex-direction: column;
    flex: 0 0 get-vw(196px);
    width: get-vw(196px); // filter area width
    min-height: get-vw(500px);
    padding: get-vw(0px) get-vw(25px);
    padding-top: get-vw(61px);
    border: none;
}