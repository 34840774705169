@import "../../../../common-v2.scss";
.cmenu-applied-filter-btn-v2 {
  &.MuiButton-root {
    min-height: get-vw(53px);
    min-width: get-vw(160px);
    margin: 4px;
    padding: get-vw(10px);
    padding-left: get-vw(20px);
    padding-right: get-vw(20px);
    box-shadow: none;
    border-radius: get-vw(60px);
    text-transform: none;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 500;
    font-size: get-font-vw(26);
    line-height: get-font-vw(31);
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
  }
}
