@import "../../../../common-v2.scss";
.cmenu-product-list-header-container {
  display: flex;
  flex-direction: column;
  margin-left: getNextPreviousPadding();
  margin-right: getNextPreviousPadding();
  z-index: 99;
  &.-open {
    margin-left: get-vw(0px);
    margin-right: get-vw(0px);
  }
  .product-list-header-section-a {
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-top: get-vw(35px);
    .all-products-header-title {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 600;
      font-size: get-font-vw(32);
      line-height: get-font-vw(38);
      letter-spacing: 0.1em;
    }
  }
  .product-list-header-section-b {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    .product-count-info {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 500;
      font-size: get-font-vw(14);
      line-height: get-font-vw(17);
      letter-spacing: 0.16em;
    }
  }
}
