@import "../../../../common-v2.scss";
.signup-completed-container {
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: get-vw(60px);
  margin-right: get-vw(60px);
  h1.header-title {
    font-family: "Bebas";
    font-style: normal;
    font-weight: 400;
    font-size: get-font-vw(36);
    line-height: get-font-vw(43);
    letter-spacing: 0.1em;
    color: #000000;
    text-align: center;
    display: flex;
    align-self: center;
  }
  .done-icon-box {
    display: flex;
    align-self: center;
  }
}
