@import "../../../../common-v2.scss";
.strike-label-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: get-vw(10px);
  .strike-container {
    display: inline-block;
    position: absolute;
    // width: 100%;
    // height: 100%;
    // top: 0;
    // left: 0;
  }
  h1.strike-label {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 600;
    font-size: get-font-vw(16);
    line-height: get-font-vw(19);
    letter-spacing: 0.15em;
    display: flex;
    align-self: center;
    &.-list {
      font-family: "Bebas";
      font-style: normal;
      font-weight: 400;
    }
    &.-overlay {
      font-size: get-font-vw(14);
      line-height: get-font-vw(17);
    }
    &.-overlay-new {
      font-size: get-font-vw(12);
      line-height: get-font-vw(15);
    }
  }
}
