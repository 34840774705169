@import "../../../../common.scss";
.range-slider-wrapper{
    display: flex;
    width: inherit;
    flex-direction: column;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
    padding-top: 8px;
    .range-slider-header{
        width: inherit;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .min-text-info{
            font-family: 'Overpass';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
        }
        .max-text-info{
            font-family: 'Overpass';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
        }
    }
    .range-slider-ui-item{
        width: 100%;
    }
}