@import "../../../../common-v2.scss";
.categories-web-menu-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    visibility: visible;
    .breadcrum-container{
        margin-top: get-vw(61px);
        padding-top: get-vw(61px);
        padding:get-vw(0px) get-vw(0px);
        margin-left: getNextPreviousPadding();
        margin-right: getNextPreviousPadding();
        &.-open{
            margin-left: get-vw(0px);
            margin-right: get-vw(0px);
        }
    }
    .product-sections-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        padding-bottom: get-vw(30px);
        margin-left: 0px;
        margin-right: 0px;
        overflow-x: hidden;
        &.-open {
            padding-left: get-vw(65px);
            padding-right: get-vw(115px);
        }
        &.-open-mobile {
            padding-left: get-vw(65px);
            padding-right: get-vw(65px);
        }
    }
}