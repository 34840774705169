@import "../../../../common.scss";
.pagination-label {
  font-family: "Overpass";
  font-style: bold;
  font-weight: 700;
  font-size: get-font-vw(18);
  line-height: get-font-vw(24);
  text-align: center;
  padding-top: get-vw(0px);
  padding-bottom: get-vw(0px);
}
