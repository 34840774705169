@import "../../../../common-v2.scss";
.cmenu-product-filter-attributes-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  .rating-label-element {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  h1.checkbox-form-control-label {
    margin-top: get-vw(7px);
    margin-bottom: get-vw(7px);
    font-family: "Barlow";
    font-style: normal;
    font-weight: 500;
    font-size: get-font-vw(14);
    line-height: get-font-vw(17);
  }
  h1.checkbox-form-control-label-selected {
    margin-top: get-vw(7px);
    margin-bottom: get-vw(7px);
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: get-font-vw(14);
    line-height: get-font-vw(17);
  }
  .show-more-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -11px;
    margin-top: get-vw(10px);
    padding-left: get-vw(42px); //checkboxwidth
  }
}
