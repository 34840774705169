@import "../../../common-v2.scss";

.locationInput {
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: get-font-vw(20px);
  line-height: get-font-vw(24px);
  letter-spacing: 0.1em;
  color: #000000;
  margin-top: get-vw(30px);
}
.locationInput::placeholder {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: get-font-vw(20px);
  line-height: get-font-vw(24px);
  letter-spacing: 0.1em;
  color: #dfdfdf;
}
.locationInputParent {
  margin-top: get-vw(33px);
}
.opendlocationbox {
  min-width: 100%;
  max-width: 100%;
  overflow-y: auto;
  background: #ffffff;
  position: absolute;
  z-index: 1000;
  border: 1px solid #000000;
  border-radius: get-vw(5px);
  box-sizing: border-box;
  scrollbar-width: thin;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: get-font-vw(16px);
  line-height: get-font-vw(20px);
  letter-spacing: 0.1em;
  color: #444;
  padding: 3px 0 0;
}

.hoverLocationBox {
  text-align: start;
  padding: 12px 15px; 
}
.hoverLocationBox p{
  margin: 0; 
}

.crossImage {
  text-align: end;
  width: 100%;
  margin-top: get-vw(20px);
  padding-right: get-vw(20px);
  box-sizing: border-box;
}

.poweredGoogle {
  width: 155px;
  padding-right: 15px;
  padding-top: get-vw(5px); 
  padding-bottom: get-vw(5px); 

}

.poweredGoogleParent {
  width: 100%;
  text-align: end;
}
