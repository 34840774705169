@import "../../../../common-v2.scss";
.login-main-container {
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: get-vw(92px);
  padding-right: get-vw(92px);
  h2.header-title {
    font-family: "Bebas";
    font-style: normal;
    font-weight: 400;
    font-size: get-font-vw(36);
    line-height: get-font-vw(43);
    letter-spacing: 0.1em;
    color: #000000;
    text-align: left;
    text-transform: uppercase;
  }
  .button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    gap: get-vw(10px);
    width: 100%;
    justify-items: center;
    align-items: center;
  }
}
@media only screen and (max-width: 600px) {
  .login-main-container {
    justify-content: start;
  }
}