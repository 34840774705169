@import "../../../../common-v2.scss";
.weight-btn-box {
  .weight-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-height: get-vw(62px);
    padding: get-vw(10px) get-vw(10px);

    h1.weight-title {
      margin-bottom: get-vw(5px);
      font-family: "Barlow";
      font-style: normal;
      font-weight: 600;
      font-size: get-font-vw(14);
      line-height: get-font-vw(17);
      letter-spacing: 0.15em;
    }

    .weight-discount-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .strike-container {
        display: inline-block;
        position: absolute;
      }

      h1.weight-discount {
        margin-bottom: get-vw(5px);
        font-family: "Barlow";
        font-style: normal;
        font-weight: 600;
        font-size: get-font-vw(16);
        line-height: get-font-vw(19);
        letter-spacing: 0.15em;
      }
    }

    h1.weight-price {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 600;
      font-size: get-font-vw(16);
      line-height: get-font-vw(19);
      letter-spacing: 0.15em;
    }
  }
}
