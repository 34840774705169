@font-face {
  font-family: "Overpass";
  font-weight: 400;
  src: local("overpass"),
    url(./statics/fonts/overpass/Overpass-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Overpass";
  font-weight: 400;
  font-style: italic;
  src: local("overpass"),
    url(./statics/fonts/overpass/Overpass-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Overpass";
  font-weight: 600;
  src: local("overpass"),
    url(./statics/fonts/overpass/Overpass-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Overpass";
  font-weight: 700;
  src: local("overpass"),
    url(./statics/fonts/overpass/Overpass-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Beloved";
  font-weight: 400;
  src: local("beloved"),
    url(./statics/fonts/beloved/beloved.otf) format("opentype");
}

@font-face {
  font-family: "Bebas";
  font-weight: 400;
  src: local("bebas"),
    url(./statics/fonts/bebas/BebasNeue-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Barlow";
  font-weight: 400;
  font-style: italic;
  src: local("barlow"),
    url(./statics/fonts/barlow/Barlow-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Barlow";
  font-weight: 500;
  src: local("barlow"),
    url(./statics/fonts/barlow/Barlow-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Barlow";
  font-weight: 600;
  src: local("barlow"),
    url(./statics/fonts/barlow/Barlow-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Barlow";
  font-weight: 700;
  src: local("barlow"),
    url(./statics/fonts/barlow/Barlow-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Petit";
  font-weight: 400;
  src: local("petit"),
    url(./statics/fonts/others/PetitFormalScript-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Piazzolla";
  font-weight: 400;
  src: local("piazzolla"),
    url(./statics/fonts/others/Piazzolla-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Playfair";
  font-weight: 400;
  src: local("playfair"),
    url(./statics/fonts/others/PlayfairDisplay-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "PlusJakarta";
  font-weight: 400;
  src: local("plusJakarta"),
    url(./statics/fonts/others/PlusJakartaSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poly";
  font-weight: 400;
  src: local("poly"),
    url(./statics/fonts/others/Poly-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "PortLligat";
  font-weight: 400;
  src: local("portLligat"),
    url(./statics/fonts/others/PortLligatSans-Regular.ttf) format("truetype");
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
