@import "../../../common.scss";

$navBar_height: getNavigationBarHeight();
$top_margin_container : get-vw(30px);
$final_top_margin : calc($navBar_height + $top_margin_container);

.product-results-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    // padding-top: $top_margin_container; // for testing only
    margin-top: $final_top_margin; // equals to height of nav bar
    background-color: white;
}