@import "../../../../common-v2.scss";
.cmenu-applied-filters-container{
    display: flex;
    flex-wrap: wrap;
    padding: 3px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 3px;
    margin-left: 0px;
    margin-right: 0px;
}