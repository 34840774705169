@import "../../../../common-v2.scss";
.cmenu-breadcrumb-container {
  padding: get-vw(0px) get-vw(0px);
  .breadcrum-title-active {
    display: flex;
    align-self: center;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 600;
    font-size: get-font-vw(12);
    line-height: get-font-vw(14);
    letter-spacing: 0.16em;
  }
  .breadcrum-title-inactive {
    display: flex;
    align-self: center;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 600;
    font-size: get-font-vw(12);
    line-height: get-font-vw(14);
    letter-spacing: 0.16em;
  }
}
