@import "../../../../common-v2.scss";
.custom-text-input-control {
    .custom-text-input {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: get-vw(20px);
        line-height: get-vw(24px);
        letter-spacing: 0.1em;
        color: #000000;
    }
    .textfield-helper-text{
        padding-top: get-vw(6px);
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: get-vw(12px);
        line-height: get-vw(14px);
        letter-spacing: 0.1em;
        color: #000000;
    }
    h2.right-title{
        padding-top: get-vw(6px);
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: get-vw(12px);
        line-height: get-vw(14px);
        text-align: right;
        letter-spacing: 0.1em;
        color: #000000;
    }
}

@media only screen and (max-width: 600px) {
    .custom-text-input-control {
        .custom-text-input { 
            font-size: get-vw(70px);
            line-height: get-vw(88px); 
            margin-top: 25px !important;
        }
        .textfield-helper-text{ 
            font-size: get-vw(40px);
            line-height: get-vw(48px); 
        }
    }
  }