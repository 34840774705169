@import "../../../common.scss";

$navBar_height: getNavigationBarHeight();
$top_margin_container: get-vw(30px);
$final_top_margin: calc($navBar_height + $top_margin_container);

.product-details-container {
  display: flex;
  flex-direction: column;
  width: inherit;
  align-self: center;
  margin-top: $final_top_margin; // equals to height of nav bar
  padding: 0px;
}
