@import "../../../../common-v2.scss";
.product-photos-container {
    .product-photos-main-container {
        width:100%;
        height: 33.7vw;
        .product-photo-assets-carousel{
            width:100%;
            height:100%;
        }
        .sproduct-image-wrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: get-vw(40px);
            .sproduct-image {
                object-fit:contain;
            }
        }
      }
      .pagination-container{
         display: flex;
         flex-direction: row;
         justify-content: flex-end;
         margin-top: get-vw(14.5px);
         width:100%;
         .pagination-btn-base{
            display: flex;
            height: get-vw(44px);
            align-items: center;
            justify-content: center;
            margin-left: get-vw(3px);
            .pagination-item{
                width: get-vw(37px);
                height: 1px;
            }
         }
        
      }
}
