@import "../../../../common-v2.scss";
$navBar_height: getNavigationBarHeight();
.cmenu-product-details-inner-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: get-vw(40px); // for testing only
  .left-container {
    // margin-left: get-vw(96.5px);
    // margin-right: get-vw(96.5px);
    width: get-vw(26px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
   
  }
  .right-container {
    // margin-left: get-vw(96.5px);
    // margin-right: get-vw(96.5px);
  }

  .center-container {
    width: 100%;
    h1.product-details-heading {
      margin-top: get-vw(62px);
      font-family: "Bebas";
      font-style: normal;
      font-weight: 400;
      font-size: get-font-vw(36);
      line-height: get-font-vw(43);
      letter-spacing: 0.1em;
      text-align: left;
      white-space: nowrap;
    }

    h1.product-brand-title {
      // position: absolute;
      margin-bottom: get-vw(16px);
      font-family: "Barlow";
      font-style: normal;
      font-weight: 600;
      font-size: get-font-vw(16);
      line-height: get-font-vw(19);
      display: flex;
      align-items: flex-end;
      letter-spacing: 0.2em;
    }

    h1.product-title {
      margin-bottom: get-vw(29.5px);
      font-family: "Bebas";
      font-style: normal;
      font-weight: 400;
      font-size: get-font-vw(32);
      line-height: get-font-vw(38);
      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
    }

    h1.effects-header {
      font-family: "Bebas";
      font-style: normal;
      font-weight: 400;
      font-size: get-font-vw(16);
      line-height: get-font-vw(19);
      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
    }

    .effect-box-container {
      display: grid;
      padding: 0;
      margin: 0;
      grid-template-columns: repeat(3, 1fr);
      gap: get-vw(10px);
      width: 100%;
      margin-top: get-vw(10.5px);
      margin-bottom: get-vw(10.5px);

      .effect-box {
        box-sizing: border-box;
        border: 1px solid #f0e8d8;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        // max-width: get-vw(122px);
        max-height: get-vw(45px);
        padding: 10px 13px;

        .effects-name {
          font-family: "Barlow";
          font-style: normal;
          font-weight: 400;
          font-size: get-font-vw(12.16);
          line-height: get-font-vw(16);
          letter-spacing: 0.1em;
          text-align: left;
          width: 100%;
          padding-right: get-vw(15px);
          text-transform: none;
        }
      }
    }

    .add-to-cart-container {
      width: inherit;
      display: flex;
      box-sizing: border-box;
      border-radius: 4px;
      height: get-vw(57px);
      align-items: center;
      justify-content: space-between;
      padding-left: get-vw(50px);
      padding-right: get-vw(37px);
      h1.add-to-cart-title {
        margin-right: get-vw(20px);
        font-family: "Barlow";
        font-style: normal;
        font-weight: 400;
        font-size: get-font-vw(20);
        line-height: get-font-vw(24);
        letter-spacing: 0.2em;
      }
      .quantity-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        h1.quantity-title {
          width: get-vw(21px);
          font-family: "Barlow";
          font-style: normal;
          font-weight: 600;
          font-size: get-font-vw(21);
          line-height: get-font-vw(25);
          padding: get-vw(0px) get-vw(13px);
          &.-up-animated {
            animation: upAnimation 0.15s;
          }
          &.-down-animated {
            animation: downAnimation 0.15s;
          }
        }
      }
    }

    .other-info-container {
      display: grid;
      justify-items: center;
      align-items: center;
      padding: 0;
      margin: 0;
      grid-template-columns: repeat(4, 1fr);
      gap: get-vw(23px);
      row-gap: get-vw(12px);
      width: 100%;
      margin-top: get-vw(44px);
      margin-bottom: get-vw(14px);
      h1.other-info-title {
        font-family: "Barlow";
        font-style: normal;
        font-weight: 600;
        font-size: get-font-vw(16);
        line-height: get-font-vw(19);
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.2em;
        &.-lineage {
          padding-bottom: get-vw(10px);
        }
      }
      h1.lineage-value {
        box-sizing: border-box;
        border-radius: 2px;
        font-family: "Barlow";
        font-style: italic;
        font-weight: 400;
        font-size: get-font-vw(16);
        line-height: get-font-vw(29);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        letter-spacing: 0.2em;
      }
      h1.other-info-value {
        padding-top: get-vw(10px);
        font-family: "Barlow";
        font-style: normal;
        font-weight: 600;
        font-size: get-font-vw(16);
        line-height: get-font-vw(29);
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.15em;
      }
      .question-mark-container {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        width: 100%;
        min-height: get-vw(67px);
        padding-top: get-vw(3px);
      }
    }

    .product-description-container {
      h1.description-header {
        margin-top: get-vw(20px);
        font-family: "Bebas";
        font-style: normal;
        font-weight: 400;
        font-size: get-font-vw(16);
        line-height: get-font-vw(19);
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
      }
    }
  }
}

.product-description-field-common {
  margin-top: get-vw(10px);
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: get-font-vw(14);
  line-height: get-font-vw(17);
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

#product-description-field-expanded {
  @extend .product-description-field-common;
}

#product-description-field {
  @extend .product-description-field-common;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
}

.sendtocart {
  animation: scaleAnimation 0.4s;
}
.part2 {
  animation: translateWithScaleAnimation 0.4s;
}
.-part3 {
  animation: opacityAnimation 0.2s;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes translateWithScaleAnimation {
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(calc(-1 * ($navBar_height + 30px))) scale(0.25);
    opacity: 0;
  }
}
@keyframes opacityAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes upAnimation {
  0% {
    transform: translateY(25px);
    visibility: visible;
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes downAnimation {
  0% {
    transform: translateY(-25px);
    visibility: visible;
  }
  100% {
    transform: translateY(0px);
  }
}
