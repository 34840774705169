@import "../../../../common-v2.scss";
.error-label-container{
    h2.error-label{
        font-family: 'Bebas';
        font-style: normal;
        font-weight: 400;
        font-size: get-vw(14px);
        line-height: get-vw(17px);
        letter-spacing: 0.1em;
    }
}