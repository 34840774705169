@import "../../../../common-v2.scss";
.weight-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px;
  margin-top: get-vw(12px);
  padding: 0px;
  // background-color: yellow;
  .weight-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: get-vw(10px) get-vw(10px);
    h1.weight-title {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 600;
      font-size: get-font-vw(14);
      line-height: get-font-vw(17);
      letter-spacing: 0.15em;
      text-align: center;
    }
    h1.weight-price {
      margin-top: get-vw(5px);
      font-family: "Barlow";
      font-style: normal;
      font-weight: 600;
      font-size: get-font-vw(14);
      line-height: get-font-vw(17);
      letter-spacing: 0.15em;
      text-align: center;
    }
  }
}
