@import "../../../../common-v2.scss";
.cmenu-product-carousel{
    display: flex;
    flex-direction: row;
    width: inherit;
    padding-top: get-vw(20px);
    padding-bottom: get-vw(0px);
    // background-color: yellow;
    &.-hide{
        display: none;
    }
}