@import "../../../../common.scss";
.product-list-header-container {
  display: flex;
  flex-direction: column;
  .product-list-header-section-a {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    .all-products-header-title {
      font-family: "Overpass";
      font-style: normal;
      font-weight: 400;
      font-size: get-font-vw(42);
      line-height: get-font-vw(42);
    }
  }
  .product-list-header-section-b {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    .product-count-info {
      font-family: "Overpass";
      font-style: normal;
      font-weight: 700;
      font-size: get-font-vw(24);
      line-height: get-font-vw(31);
    }
  }
}
