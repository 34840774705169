@import "../../../../common.scss";
.product-card{
    display: flex;
    flex-direction: column;
    height: 100%;
    //340, 170
    //392, 196
    .MuiCardContent-root {
        // padding: 0px !important;
        padding: 8px 10px,
    }
    .product-title{
        font-family: 'Overpass';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        padding-top: 18px;
        height: 48px;
    }
    .product-price-info{
        font-family: 'Overpass';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        // color: #00BC6F;
        text-align: left;
        padding-top: 10px;
    }
}