@import "../../../../common-v2.scss";
.breadcrumb-container {
  padding: get-vw(0px) get-vw(0px);
  .breadcrum-title-active {
    font-family: "Overpass";
    font-style: bold;
    font-weight: 700;
    font-size: get-font-vw(24);
    line-height: get-font-vw(24);
    color: "#000";
  }
  .breadcrum-title-inactive {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 500;
    font-size: get-font-vw(24);
    line-height: get-font-vw(24);
    color: "#999999";
  }
}
