@import "../../../../common-v2.scss";
$navBar_height: getNavigationBarHeight();
.cmenu-product-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  .product-card-container {
    position: relative;
    display: flex;
    flex-direction: column;
    .product-image-container {
      -webkit-box-orient: vertical;
      -webkit-box-align: center;
      display: -webkit-box;
      .product-image-area-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .product-card-content {
    margin-top: auto;
    padding: 0px 0px;
    padding-top: get-vw(7px);
    min-height: get-vw(100px); //need to lookinto this
    z-index: 99;
    cursor: pointer;
    &:last-child {
      padding-bottom: 0px;
    }
    &.-mobile{
      padding-top: get-vw(25px);
    }
    .product-title-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: get-vw(3.5px);
      &.-mobile{
        margin-top: get-vw(15px);
      }
      .product-title {
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        font-family: "Barlow";
        font-style: normal;
        font-weight: 600;
        font-size: get-font-vw(14);
        line-height: get-font-vw(17);
        text-align: left;
        letter-spacing: 0.2em;
      }

      .rating-label-container {
        // display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: get-vw(2px);
        .rating-title-label {
          padding-left: get-vw(3px);
          font-family: "Barlow";
          font-style: normal;
          font-weight: 700;
          font-size: get-font-vw(12);
          line-height: get-font-vw(14);
          text-align: left;
          letter-spacing: 0.2em;
        }
      }
    }
    .product-brand-info-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-top: get-vw(5px);
      &.-mobile{
        margin-top: get-vw(15px);
      }
      .brand-title {
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        font-family: "Barlow";
        font-style: normal;
        font-weight: 500;
        font-size: get-font-vw(12);
        line-height: get-font-vw(14);
        text-align: left;
        letter-spacing: 0.2em;
      }
    }
    .product-price-info-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: get-vw(3px);
      &.-mobile{
        margin-top: get-vw(15px);
      }
      .product-price-label {
        font-family: "Bebas";
        font-style: normal;
        font-weight: 400;
        font-size: get-font-vw(16);
        line-height: get-font-vw(19);
        text-align: left;
        letter-spacing: 0.2em;
      }
      .product-price-label-mobile {
        font-family: "Bebas";
        font-style: normal;
        font-weight: 400;
        font-size: get-font-vw(16);
        line-height: get-font-vw(19);
        text-align: left;
        letter-spacing: 0.2em;
        // margin-right: get-vw(30px);
        // margin-left: get-vw(30px);
      }
      .product-weight-label {
        margin-left: get-vw(8px);
        font-family: "Barlow";
        font-style: normal;
        font-weight: 400;
        font-size: get-font-vw(14);
        line-height: get-font-vw(17);
        text-align: left;
        letter-spacing: 0.2em;
      }
    }
  }
}
.animated-product-image {
  position: absolute;
  z-index: 9999;
}

.sendtocart {
  animation: scaleAnimation 0.4s;
}
.part2 {
  animation: translateWithScaleAnimation 0.4s;
}
.-part3 {
  animation: opacityAnimation 0.2s;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes translateWithScaleAnimation {
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(calc(-1 * ($navBar_height + 30px))) scale(0.25);
    opacity: 0;
  }
}
@keyframes opacityAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
