@import "../../../../common-v2.scss";

.verify-otp-container {
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: get-vw(92px);
  margin-right: get-vw(92px);
  h2.header-title {
    font-family: "Bebas";
    font-style: normal;
    font-weight: 400;
    font-size: get-font-vw(36);
    line-height: get-font-vw(43);
    letter-spacing: 0.1em;
    color: #000000;
    text-align: left;
    text-transform: uppercase;
  }
  h2.sub-header-title {
    margin-top: get-vw(14px);
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: get-font-vw(18);
    line-height: get-font-vw(22);
    letter-spacing: 0.1em;
    color: #000000;
    text-align: left;
  }
  .otpBox {
    margin-top: get-vw(35px);
    width: inherit;
    height: 60px;
    .input {
      font-family: "Barlow";
      font-style: normal;
      font-weight: 400;
      font-size: get-font-vw(18);
      line-height: get-font-vw(22);
    }
  }
  .button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    gap: get-vw(10px);
    width: 100%;
    justify-items: center;
    align-items: center;
  }
}
